"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/**
 * @author Bryan Marvila
 * @email bryan.marvila@stilingue.com.br
 * @create date 2021-07-14 10:17:05
 * @modify date 2021-11-03 16:32:04
 * @desc Created to manager performance informations
 */

(function () {
  'use strict';

  window.entriesPerformance = {
    getSpeedIndexEntry: function getSpeedIndexEntry() {
      var entry = performance.getEntriesByName('largest-contentful-paint', 'mark').pop();
      return entry;
    },
    getFirstContentfulPaintEntry: function getFirstContentfulPaintEntry() {
      var _performance$getEntri = performance.getEntriesByName('first-contentful-paint', 'paint'),
        _performance$getEntri2 = _slicedToArray(_performance$getEntri, 1),
        entry = _performance$getEntri2[0];
      return entry;
    },
    getCpuIdleEntry: function getCpuIdleEntry() {
      var _performance$getEntri3 = performance.getEntriesByType('navigation'),
        _performance$getEntri4 = _slicedToArray(_performance$getEntri3, 1),
        entry = _performance$getEntri4[0];
      return {
        startTime: entry.domInteractive
      };
    },
    getTimeInteractiveEntry: function getTimeInteractiveEntry() {
      var allInitialResource = this.getInitialResources();
      return this.maxValueOfObjectList(allInitialResource, 'responseEnd');
    },
    markFirstMeaningfulPaint: function markFirstMeaningfulPaint() {
      this.createPerformanceMark('first-meaningful-paint');
    },
    getFirstMeaningfulPaint: function getFirstMeaningfulPaint() {
      var _performance$getEntri5 = performance.getEntriesByName('first-meaningful-paint'),
        _performance$getEntri6 = _slicedToArray(_performance$getEntri5, 1),
        entry = _performance$getEntri6[0];
      return entry;
    },
    markMaxPotentialFirstInputDelay: function markMaxPotentialFirstInputDelay() {
      this.markPerformanceMeasure('max-potential-first-input-delay');
    },
    getMaxPotentialFirstInputDelay: function getMaxPotentialFirstInputDelay() {
      var _performance$getEntri7 = performance.getEntriesByName('max-potential-first-input-delay'),
        _performance$getEntri8 = _slicedToArray(_performance$getEntri7, 1),
        entry = _performance$getEntri8[0];
      return entry;
    },
    getLongerResource: function getLongerResource() {
      return this.maxValueOfObjectList(this.getInitialResources(), 'duration');
    },
    getPerformanceData: function getPerformanceData() {
      var _performance$getEntri9 = performance.getEntriesByType('navigation'),
        _performance$getEntri10 = _slicedToArray(_performance$getEntri9, 1),
        entry = _performance$getEntri10[0];
      if (!(entry instanceof PerformanceNavigationTiming)) {
        return {};
      }
      var _entry$toJSON = entry.toJSON(),
        name = _entry$toJSON.name,
        entryType = _entry$toJSON.entryType,
        duration = _entry$toJSON.duration,
        initiatorType = _entry$toJSON.initiatorType,
        redirectCount = _entry$toJSON.redirectCount,
        type = _entry$toJSON.type,
        nextHopProtocol = _entry$toJSON.nextHopProtocol,
        workerStart = _entry$toJSON.workerStart,
        decodedBodySize = _entry$toJSON.decodedBodySize,
        encodedBodySize = _entry$toJSON.encodedBodySize,
        transferSize = _entry$toJSON.transferSize;
      var speedIndex = this.getSpeedIndexEntry();
      var firstMeaningfulPaint = this.getFirstMeaningfulPaint();
      var firstContentfulPaintEntry = this.getFirstContentfulPaintEntry();
      var maxPotentialFirstInputDelay = this.getMaxPotentialFirstInputDelay();
      var performanceData = {
        unload_event_timing: entry.unloadEventEnd - entry.unloadEventStart,
        redirect_timing: entry.redirectEnd - entry.redirectStart,
        domain_lookup_timing: entry.domainLookupEnd - entry.domainLookupStart,
        connect_timing: entry.connectEnd - entry.connectStart,
        request_timing: entry.responseStart - entry.requestStart,
        response_timing: entry.responseEnd - entry.responseStart,
        dom_timing: entry.domComplete - entry.domInteractive,
        dom_content_loaded_timing: entry.domContentLoadedEventEnd - entry.domContentLoadedEventStart,
        load_event_timing: entry.loadEventEnd - entry.loadEventStart,
        name: name,
        entry_type: entryType,
        duration: duration,
        initiator_type: initiatorType,
        redirect_count: redirectCount,
        type: type,
        next_hop_protocol: nextHopProtocol,
        worker_start: workerStart,
        decoded_body_size: decodedBodySize,
        encoded_body_size: encodedBodySize,
        transfer_size: transferSize,
        speed_index: speedIndex ? speedIndex.startTime : 0,
        first_contentful_paint: firstContentfulPaintEntry ? firstContentfulPaintEntry.startTime : 0,
        cpu_idle: this.getCpuIdleEntry().startTime,
        time_interactive: this.getTimeInteractiveEntry().responseEnd,
        first_meaningful_paint: firstMeaningfulPaint ? firstMeaningfulPaint.startTime : 0,
        max_potential_first_input_delay: maxPotentialFirstInputDelay ? maxPotentialFirstInputDelay.duration : 0,
        longer_resource: this.getLongerResource().duration,
        url_longer_resource: this.getLongerResource().name,
        initial_resource_length: this.getInitialResources().length
      };
      return performanceData;
    },
    logNavigationTiming: function logNavigationTiming() {
      // Show it in a nice table in the developer console
      console.table(this.getPerformanceData());
    },
    markPerformanceMeasure: function markPerformanceMeasure(measureName) {
      var _performance$getEntri11 = performance.getEntriesByName("".concat(measureName, "-mark-start")),
        _performance$getEntri12 = _slicedToArray(_performance$getEntri11, 1),
        entry = _performance$getEntri12[0];
      if (entry !== undefined) {
        this.finishPerformanceMeasure(measureName);
      } else {
        this.initPerformanceMeasure(measureName);
      }
    },
    initPerformanceMeasure: function initPerformanceMeasure(measureName) {
      this.createPerformanceMark("".concat(measureName, "-mark-start"));
    },
    finishPerformanceMeasure: function finishPerformanceMeasure(measureName) {
      try {
        this.createPerformanceMark("".concat(measureName, "-mark-end"));
        this.createPerformanceMeasure(measureName, "".concat(measureName, "-mark-start"), "".concat(measureName, "-mark-end"));
        var _performance$getEntri13 = performance.getEntriesByName(measureName, 'measure'),
          _performance$getEntri14 = _slicedToArray(_performance$getEntri13, 1),
          entry = _performance$getEntri14[0];
        console.table(entry.toJSON());
      } catch (e) {
        console.warn("Are you sure you started metrics ".concat(measureName, " (initMeasure)?"));
        console.error(e.message);
      }
    },
    createPerformanceMark: function createPerformanceMark(markName) {
      if (performance.mark === undefined) {
        throw new Error('Create mark: performance.mark Not supported');
      }
      performance.mark(markName);
    },
    createPerformanceMeasure: function createPerformanceMeasure(measureName, markStart, markEnd) {
      if (performance.measure === undefined) {
        throw new Error('Create measure: performance.measure Not supported');
      }
      var measureList = performance.getEntriesByName(measureName, 'measure');
      var markStartList = performance.getEntriesByName(markStart, 'mark');
      var markEndList = performance.getEntriesByName(markEnd, 'mark');
      if (measureList.length > 0) {
        throw new Error("Measure ".concat(measureName, " already exists"));
      }
      if (markStartList.length < 1) {
        throw new Error("Mark ".concat(markStart, " not exists"));
      }
      if (markEndList.length < 1) {
        throw new Error("Mark ".concat(markEnd, " not exists"));
      }
      performance.measure(measureName, markStart, markEnd);
    },
    getResourceEntries: function getResourceEntries(fnFilter) {
      var allEntries = performance.getEntriesByType('resource');
      if (fnFilter) {
        return allEntries.filter(fnFilter);
      }
      return allEntries;
    },
    maxValueOfObjectList: function maxValueOfObjectList(list, attr) {
      var lastResource = null;
      list.forEach(function (el) {
        if (!lastResource || lastResource[attr] < el[attr]) {
          lastResource = el;
        }
      });
      return lastResource;
    },
    getInitialResources: function getInitialResources() {
      var _performance$getEntri15 = performance.getEntriesByType('navigation')[0],
        duration = _performance$getEntri15.duration,
        domInteractive = _performance$getEntri15.domInteractive;
      var speedIndex = this.getSpeedIndexEntry() ? this.getSpeedIndexEntry().startTime : 0;
      var longestNavigationTime = Math.max(speedIndex, duration, domInteractive);
      return this.getResourceEntries(function (e) {
        return e.startTime <= longestNavigationTime;
      });
    },
    trackPerformance: function trackPerformance() {
      var _this = this;
      var trackTimeToInterval = 100;
      var trackinterval = setInterval(function () {
        if (_this.navigationEntryIsLoaded()) {
          clearInterval(trackinterval);
          return new Promise(function (resolve, reject) {
            var metadata = _this.getPerformanceData();
            if (!Object.keys(metadata).length) {
              reject('No performance data');
            }
            window.WarroomAnalytics.track('stilingue_track_performance', metadata).then(function (response) {
              resolve(response);
            });
          });
        }
      }, trackTimeToInterval);
    },
    navigationEntryIsLoaded: function navigationEntryIsLoaded() {
      var entryNavigation = this.getPerformanceData();
      return entryNavigation.duration > 0;
    }
  };
})();
try {
  var paintObserver = new PerformanceObserver(function (entryList) {
    var entries = entryList.getEntries();
    var lastEntry = entries[entries.length - 1];
    performance.mark('largest-contentful-paint', {
      startTime: lastEntry.renderTime || lastEntry.loadTime
    });
  });
  paintObserver.observe({
    type: 'largest-contentful-paint',
    buffered: true
  });
} catch (e) {
  // Do nothing if the browser doesn't support this API.
}
function buffer_full() {
  var addBufferSize = 50;
  var resourceSize = window.entriesPerformance.getResourceEntries().length;
  var bufferSize = resourceSize + addBufferSize;
  console.log('WARNING: Resource Timing Buffer is FULL! Buffer size adjusted to ', bufferSize);
  performance.setResourceTimingBufferSize(bufferSize);
}
performance.onresourcetimingbufferfull = buffer_full;